/**
 * Created S/25/12/2010
 * Updated S/30/12/2023
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://www.luigifab.fr/
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& document &&& */
* { margin:0; padding:0; font-family:ubuntu, sans-serif; background-clip:padding-box; background-repeat:no-repeat; }
code, code *, pre, pre *, kbd { font-family:"Courier New", monospace; }

body { font-size:0.7em; }
@page { margin:1cm 1cm 3cm; }

@media screen {
	html { background-color:#CCC; }
	body { margin:auto; width:21cm; overflow-y:scroll; font-size:0.75em; }
	div.main { margin:3em 0; background-color:white; outline:1em solid white; }
}

form, input, select, option, button, hr { display:none; }
abbr { border-bottom:0; text-decoration:none; }

img { overflow:hidden; color:transparent; }
a img { display:inline-block; border:0; }
a { color:blue; text-decoration:none; }

div.header, div.footer, div.breadcrumbs, div.notice, p.review, div.controls, div.screens, br.np { display:none; }

@font-face {
	src:local("Schoolboy Text"), url("../fonts/doc-schoolboytext.ttf") format("truetype");
	font-family:SchoolboyText; font-style:normal; font-weight:400; font-display:swap;
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& texte &&& */
h1 {
	margin:0 0 1em;
	font-size:1.9em; font-weight:700; page-break-after:avoid;
}

h2 {
	display:flex; margin:2em 0 1.5em; line-height:2em;
	font-size:1.2em; font-weight:700; page-break-after:avoid;
	border-bottom:1px solid black;
}

h3 {
	margin:1.4em 1em 1.1em;
	font-size:1.1em; font-weight:700; page-break-after:avoid;
}

h4 {
	margin:1.4em 2.5em 1.1em; clear:both;
	font-size:0.95em; font-weight:700; font-style:italic; page-break-after:avoid;
}

h2 a, h3 a { flex:1; color:currentColor; }

p { margin:0.8em 0 1em; line-height:1.42857143; hyphens:auto; -moz-hyphens:auto; -ms-hyphens:auto; -webkit-hyphens:auto; }
kbd  { display:inline-block; padding:0 0.2em; font-size:0.9em; }
code { display:inline-block; padding:0 0.3em; font-size:0.9em; background-color:#F5F5F5; }
code span.optional { font-style:italic; color:#676767; }
em { padding-right:1px; }

p.code strong { margin-left:0.3em; }
p.code button { margin-left:0.4em; }

/*rtl:begin:ignore*/
p.code code {
	display:block; margin:0.4em 0 0.8em; padding:0.5em 1em; overflow:hidden; text-align:left;
	white-space:pre-wrap; word-wrap:break-word; border-left:3px solid #BBB;
}

pre.highlight { display:block; margin:1em 0; padding:0.6em 1em; text-align:left; border:1px solid #CCC; }
pre.highlight span.code { font-size:1.2em; }

pre.highlight code {
	display:block; margin-top:0.7em; padding:0; line-height:1.42857143; overflow:hidden;
	background-color:white; white-space:pre-wrap; word-wrap:break-word; tab-size:4; -moz-tab-size:4;
}

pre.highlight code.dif { font-size:0.8em; }
pre.highlight code.dif ins, pre.highlight code.dif del { font-weight:700; text-decoration:none; }
/*rtl:end:ignore*/

ol, ul { display:block; margin:0.8em 0 1.4em; }
ol ol { margin:0.45em 0 0.5em; }
li { display:block; margin:0.1em 0; line-height:1.42857143; }

ol.arrow, ul.arrow { margin:0.8em 0 0.9em; }
ol.arrow li:before, ul.arrow li:not(.code):before { content:"➤\0000A0"; }
p:not(.mc) + ul.arrow { margin-top:-0.5em; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& page &&& */
div.main { overflow:hidden; page-break-after:always; }

div.main h1 { min-height:1.6em; font:2.3em SchoolboyText; }
div.main h1 span, div.main h1 abbr { font-family:SchoolboyText; }

img.screenright { display:block; float:right; margin:-1.5em 0 1em 1.4em; background-color:#EEE; }

hr.s { display:block; margin:1.3em 1em 1em; border:0; border-top:1px solid #CCC; }
dl.v { position:relative; padding:0.2em 0.2em 0.3em 4em; font-size:0.9em; line-height:1.42857143; border:1px solid #CCC; }
dl.v dt { position:absolute; left:0; padding:0.25em; width:3.5em; text-align:center; }
dl.v dd { padding:0.25em 0; }

/* &&&&&&&&&&&&&&&&&&&& sommaire et options &&& */
div.contents { float:right; margin:-6em 0 1em 1em; padding:1em 0 1em 1em; width:22em; }

div.contents h2 { display:block; margin:0 0 0.7em; padding:0; line-height:inherit; border-bottom:0; background:none; }
div.contents h2 span { float:right; font-size:0.85em; font-weight:400; }

div.contents a.logo {
	display:block; margin:auto; width:85%; height:100px;
	color:transparent; background:no-repeat center; background-size: contain; opacity:0.2;
}

div.contents a.logo.python { background-image:url("../python/images/logo-python.svg"); }
div.contents a.logo.adminer { background-image:url("../adminer/images/logo-adminer.png"); }
div.contents a.logo.redmine { background-image:url("../redmine/images/logo-redmine.png"); }
div.contents a.logo.openmage { background-image:url("../openmage/images/logo-openmage.png"); }

div.contents ol { margin:0; counter-reset:contents; }
div.contents li { line-height:135%; }
div.contents ol li:before { content:counter(contents) ". "; counter-increment:contents; }

div.contents ol ol { counter-reset:contents2; }
div.contents ol ol li:before { content:counter(contents) "." counter(contents2) ". "; counter-increment:contents2; }

/* &&&&&&&&&&&&&&&&&&&& tableau des tailles &&& */
table.sizes { float:right; margin-left:2em; font-size:0.85em; border-collapse:collapse; }

table.sizes thead th { padding:0.3em 0.6em; width:25%; text-align:center; border-bottom:1px dotted #DDD; border-right:1px dotted #DDD; }
table.sizes thead th:first-child { width:50%; text-align:left; }

table.sizes tbody td:first-child { text-align:left; white-space:normal; }
table.sizes tbody td { padding:0.3em 0.6em; text-align:right; border-right:1px dotted #DDD; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& pygments &&& **
 * https://github.com/richleland/pygments-css
 * https://github.com/jwarby/jekyll-pygments-themes (zenburn/github) */
@media all {
	pre .c { color:#408080; font-style:italic; }
	pre .err { border:1px solid #FF0000; }
	pre .k { color:#008000; font-weight:700; }
	pre .o { color:#666666; }
	pre .ch { color:#408080; font-style:italic; }
	pre .cm { color:#408080; font-style:italic; }
	pre .cp { color:#BC7A00; }
	pre .cpf { color:#408080; font-style:italic; }
	pre .c1 { color:#408080; font-style:italic; }
	pre .cs { color:#408080; font-style:italic; }
	pre .gd { color:#A00000; }
	pre .ge { font-style:italic; }
	pre .gr { color:#FF0000; }
	pre .gh { color:#000080; font-weight:700; }
	pre .gi { color:#00A000; }
	pre .go { color:#888888; }
	pre .gp { color:#000080; font-weight:700; }
	pre .gs { font-weight:700; }
	pre .gu { color:#800080; font-weight:700; }
	pre .gt { color:#0044DD; }
	pre .kc { color:#008000; font-weight:700; }
	pre .kd { color:#008000; font-weight:700; }
	pre .kn { color:#008000; font-weight:700; }
	pre .kp { color:#008000; }
	pre .kr { color:#008000; font-weight:700; }
	pre .kt { color:#B00040; }
	pre .m { color:#666666; }
	pre .s { color:#BA2121; }
	pre .na { color:#7D9029; }
	pre .nb { color:#008000; }
	pre .nc { color:#0000FF; font-weight:700; }
	pre .no { color:#880000; }
	pre .nd { color:#AA22FF; }
	pre .ni { color:#999999; font-weight:700; }
	pre .ne { color:#D2413A; font-weight:700; }
	pre .nf { color:#0000FF; }
	pre .nl { color:#A0A000; }
	pre .nn { color:#0000FF; font-weight:700; }
	pre .nt { color:#008000; font-weight:700; }
	pre .nv { color:#19177C; }
	pre .ow { color:#AA22FF; font-weight:700; }
	pre .w { color:#bbbbbb; }
	pre .mb { color:#666666; }
	pre .mf { color:#666666; }
	pre .mh { color:#666666; }
	pre .mi { color:#666666; }
	pre .mo { color:#666666; }
	pre .sa { color:#BA2121; }
	pre .sb { color:#BA2121; }
	pre .sc { color:#BA2121; }
	pre .dl { color:#BA2121; }
	pre .sd { color:#BA2121; font-style:italic; }
	pre .s2 { color:#BA2121; }
	pre .se { color:#BB6622; font-weight:700; }
	pre .sh { color:#BA2121; }
	pre .si { color:#BB6688; font-weight:700; }
	pre .sx { color:#008000; }
	pre .sr { color:#BB6688; }
	pre .s1 { color:#BA2121; }
	pre .ss { color:#19177C; }
	pre .bp { color:#008000; }
	pre .fm { color:#0000FF; }
	pre .vc { color:#19177C; }
	pre .vg { color:#19177C; }
	pre .vi { color:#19177C; }
	pre .vm { color:#19177C; }
	pre .il { color:#666666; }
}